<template>
  <div class="profile-data-wrapper financial-graphs earnings" v-show="!loading">
    <div class="profile-options">
      <div class="row">
        <div class="col">
          <router-link class="btn primary-btn" :to="{ name: 'FinancialGraphs' }"
            ><font-awesome-icon :icon="['fas', 'arrow-left']"
          /></router-link>
        </div>
        <div class="col flex-grow-1 d-flex justify-content-end">
          <Select2
            id="filter-period"
            :options="filters.period.options"
            v-model="filters.period.value"
            class="select2-box"
            :resetOptions="false"
            @select="getEarnings()"
          />
        </div>
        <!-- <div class="col">
          <Select2
            id="filter-connect-types"
            v-model="filters.client.value"
            :options="filters.client.options"
            class="select2-box"
            :resetOptions="false"
            :placeholder="`Connect Type`"
            @select="getConnects()"
          />
        </div> -->
      </div>
    </div>
    <div class="row grid-2">
      <div class="col">
        <div class="earning-gauge-chart-wrap">
          <h2 class="section-heading text-transform-uppercase">
            Company Performance (MTD)
          </h2>
          <div
            class="
              earning-gauge-chart
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <GaugeChart :options="earningGauge.options"></GaugeChart>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="earning-chart">
          <h2 class="section-heading text-transform-uppercase">
            Earnings ({{ filters.period.value }})
          </h2>
          <template
            v-if="
              !earningData ||
              earningData.reduce((partialSum, a) => partialSum + a.z, 0) === 0
            "
          >
            <div
              class="
                no-data-wrap
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <img src="@/assets/images/no-graph.png" alt="no-graph" />
              <div class="desc">No data<br />to show</div>
            </div>
          </template>
          <template v-else>
            <chart-js
              :type="`bar`"
              :datasets="earningChart.datasets"
              :labels="earningChart.labels"
              :options="earningChart.options"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="custom-table no-arrows">
      <table id="datatable">
        <thead>
          <tr>
            <th>Time Period</th>
            <th>Earnings($)</th>
            <th>Upwork Commission (incl.)($)</th>
            <th>WHT (incl.)($)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(earning, index) in earningData" :key="index">
            <td>{{ earning.x }}</td>
            <td>{{ earning.y.earning }}</td>
            <td>{{ earning.y.upwork_comminsion }}</td>
            <td>{{ earning.y.WHT }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="profile-footer d-flex justify-content-end">
      <button class="primary-btn" type="button" @click="downloadCsv()">
        <font-awesome-icon :icon="['fa', 'download']" /> Download
      </button>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronRight,
  faDownload,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
library.add(faChevronRight, faDownload, faArrowLeft);

import financialEarningService from "../services/financialEarning.service.js";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import Select2 from "../components/Select2.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import ChartJs from "../components/ChartJs.vue";
import GaugeChart from "../components/GaugeChart.vue";
import common from "../mixins/common";
import dataLabels from "chartjs-plugin-datalabels";

export default {
  components: { Select2, FontAwesomeIcon, ChartJs, GaugeChart },
  mixins: [common],
  data() {
    return {
      loading: false,
      filters: {
        period: {
          options: ["Monthly", "Annually"],
          value: "Monthly",
        },
        client: {
          options: [],
          value: "",
        },
      },
      earningData: [],
      earningGauge: {
        options: {},
      },
      earningChart: {
        labels: [],
        datasets: [
          {
            label: "Earning",
            data: [],
            borderColor: "#6fda45",
            backgroundColor: "#6fda45",
          },
        ],
        plugins: [dataLabels],
        options: {
          layout: {
            padding: {
              top: 30,
              bottom: 30,
            },
          },
          indexAxis: "y",
          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: false,
            title: false,
            datalabels: {
              backgroundColor: "transparent",
              borderRadius: 4,
              color: "#fff",
              font: {
                weight: "400",
                size: '16px "Montserrat", sans-serif',
              },
              padding: 0,
              formatter: function (value) {
                return `${Math.round(value)}`;
              },
            },
          },
        },
      },
      sideModal: {
        connect: {
          add: {
            show: false,
          },
        },
      },
    };
  },
  async mounted() {
    await this.getEarnings();
    this.loading = false;
  },
  methods: {
    async getEarnings() {
      let vm = this;
      try {
        vm.$store.commit("loadingStatus", true);
        const response = await financialEarningService.get(
          vm.filters.period.value
        );
        vm.earningData = response.earnings.slice().reverse();
        /* Prepare data form bar chart */
        vm.earningChart.labels = response.earning_graph.labels;
        vm.earningChart.datasets[0].data = response.earning_graph.datasets;

        vm.billingShouldBeTillDatePercentage =
          response.billingShouldBeTillDatePercentage;
        vm.currentMonthEaringPercentage = response.currentMonthEaringPercentage;
        vm.currentMonthEaringTillDate = response.currentMonthEaringTillDate;
        vm.billableHigherLimit = response.billableHigherLimit;
        vm.billingShouldBeTillDate = response.billingShouldBeTillDate;

        vm.earningGauge.options = {
          needleValue: vm.currentMonthEaringPercentage,
          arcDelimiters: [
            vm.currentMonthEaringPercentage,
            vm.billingShouldBeTillDatePercentage,
          ],
          hasNeedle: true,
          arcColors: ["#ffc107", "#e1e1e1", "#eee", "#eee"],
          needleColor: "#000",
          arcOverEffect: false,
          arcLabels: [
            vm.currentMonthEaringTillDate,
            vm.billingShouldBeTillDate,
          ],
          rangeLabel: ["0", vm.billableHigherLimit],
          labelsFont: '"Montserrat", sans-serif',
          chartWidth: 400,
        };

        var dT = $("#datatable").DataTable();
          dT.destroy();

        vm.$nextTick(function () {
          $("#datatable").DataTable({
            dom: "Bfrtip",
            buttons: [
              {
                extend: "csv",
                filename: "Finanical Earnings "+vm.filters.period.value,
                extension: ".csv",
              },
            ],
            iDisplayLength: 25,
            bLengthChange: false,
            order: [[0, "desc"]],
            /* Disable initial sort */
            aaSorting: [],
            info: false,
          });
          $(".dt-buttons, .dataTables_filter").hide();
        });
        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
    downloadCsv: async function () {
      $(".buttons-csv").click();
    },
    closeSideModal() {
      document.body.classList.remove("lmodal-open");
      setTimeout(() => {
        this.sideModal.connect.add.show = false;
      }, 300);
    },
    async openSideModal(action) {
      let vm = this;

      await vm.$store.dispatch("getUserDetails");
      if (
        vm.userPermissions &&
        vm.userPermissions.financial_graphs &&
        vm.userPermissions.financial_graphs.length > 0 &&
        vm.userPermissions.financial_graphs.includes("View")
      ) {
        if (
          action === "addConnect" &&
          vm.userPermissions.financial_graphs.includes("Add")
        ) {
          vm.sideModal.connect.add.show = true;
        }
        setTimeout(() => {
          document.body.classList.add("lmodal-open");
        }, 300);
      }
    },
  },
};
</script>
