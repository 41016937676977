<template>
    <vue-gauge
        width="400"
        :refid="'earning-gauge'"
        :options="options"
        v-if="options.needleValue"
    ></vue-gauge>
</template>
<script>
import VueGauge from "vue-gauge";

export default {
    data() {
        return {
            earningGauge: {
                options:  {
                    needleValue: 0,
                    arcDelimiters: [37, 50],
                    hasNeedle: true,
                    arcColors: ["#ffc107", "#e1e1e1", "#eee", "#eee"],
                    needleColor: "#000",
                    arcOverEffect: false,
                    arcLabels: ["1200"],
                    rangeLabel: ["0", "3200"],
                    labelsFont: '"Montserrat", sans-serif',
                    chartWidth: 400,
                },
            },
        }
    },
    components: { VueGauge },
    props: {
        options: {
            type: Object,
            default: () => {
                return {
                    needleValue: 0,
                    arcDelimiters: [37, 50],
                    hasNeedle: true,
                    arcColors: ["#ffc107", "#e1e1e1", "#eee", "#eee"],
                    needleColor: "#000",
                    arcOverEffect: false,
                    arcLabels: ["0"],
                    rangeLabel: ["0", "3200"],
                    labelsFont: '"Montserrat", sans-serif',
                    chartWidth: 400,
                }
            }
        }
    },
    mounted() {
        console.log('this.needValue------', this.options);
    },
    watch: {
        options(value) {
            console.log('value---', value.needleValue);
            this.earningGauge.options = value;
        }
    }
}
</script>